import DefaultLayout from "~ui/DefaultLayout/DefaultLayout";
import InjectorAd from "~features/ads/InjectorAd";
import ExploreViewStories from "~features/homepage/ExploreViewStories";
import LatestPodcasts from "~features/homepage/LatestPodcasts";
import TopStories from "~features/homepage/TopStories";
import IssueModule from "~features/homepage/IssueModule";
import GamesModule from "~features/homepage/GamesModule";
import MostPopular from "~features/homepage/MostPopular";
import NewsletterSignup from "~features/homepage/NewsletterSignup";
import FeaturedProject from "~features/homepage/FeaturedProject";
import { usePageData } from "~core/hooks/use-page";

export default function Index({ body = "" }) {
  const { featuredProject: articles } = usePageData();

  return (
    <DefaultLayout fullLogo={true} showActivePromo={true} showSubscribeLink={true}>
      {/* a11y best practice is to always have one and only one h1 element on the page */}
      <h1 className="sr-only">Scientific American</h1>
      <TopStories />
      <ExploreViewStories />
      <InjectorAd position="hp-1" margin={false} />
      <IssueModule />
      <GamesModule />
      <NewsletterSignup />
      <InjectorAd position="hp-2" margin={false} />
      <LatestPodcasts />
      <InjectorAd position="hp-3" margin={false} />
      <MostPopular />
      <InjectorAd position="hp-4" margin={false} />
    </DefaultLayout>
  );
}
